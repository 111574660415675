<template>
  <div class="conteneur about">
    <div class="sidebar">
      <sidebar />
    </div>
    <div class="contenuDroite">
      <div class="container">
        <h1 class="my-5 text-center">
          Application de knowledge management - MyDataBall
        </h1>
        <p>
          <span class="mx-3"></span>Cette application web a été réalisé par deux
          apprenants de la Fabrique du numérique, Quantin Amirault et Alexandre
          Nau, lors d'un stage au sein de l'entreprise R2C-System sous la
          responsabilité de Stéphane Chauvin.
        </p>
        <p>
          <span class="mx-3"></span>Cet outil permet à l'utilisateur d'effectuer
          une recherche et de trier les résultats dans des clusters en fonction
          des mots présents sur les différents sites obtenus en réponse à la
          requête.
        </p>
        <p>
          <span class="mx-3"></span>Cette page a été créée en s'inspirant de
          l'interface fonctionnelle de
          <a href="https://search.carrot2.org/#/search/web">Carrot²</a>. Nous
          avons réalisé la partie front à l'aide des framework VueJS, et
          Boostrap. Les requètes sont réalisées à l'aide du méta-moteur de
          recherche
          <a href="https://www.etools.ch/">Etools</a>
          (recherche sur ~12 moteurs de recherches comme Google, Bing, Yahoo,
          ...). Différents algorithmes (Lingo, STC, k-means) utilisés notamment
          dans l'application Carrot² permettent le tri des résultats.
        </p>
        <p>
          <span class="mx-3"></span>Vous pouvez nous contactez
          <router-link to="/contact"> ici. </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import sidebar from "@/components/sidebar.vue";

export default {
  name: "about",
  components: {
    sidebar,
  },
};
</script>

<style scoped>
h1 {
  font-size: 24px;
  color: #22609e;
}
.conteneur.about {
  height: 100vh;
}
.conteneur {
  display: flex;
  height: 100%;
}
.contenuDroite {
  flex: 98%;
  text-align: justify;
}
</style>
